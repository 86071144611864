body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.error {
  color: #c50707;
  font-weight: bold;
  font-size: 1rem;
}
.head {
  margin-top: 0;
  padding: 20px;
}
.qr-cont {
  position: relative;
}
.qr-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border: 75px solid rgba(0, 0, 0, 0.5);
  height: 100%;
  box-sizing: border-box;
}
.qr-mask .scan {
  animation: MoveUpDown 5s linear infinite;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 2px solid #e86441;
  width: 100%;
}
.slider {
  background: #f0f9ff;
  padding: 1rem 8rem;
}
.slider h3 {
  font-weight: 500;
  font-size: 1.5rem;
}
.glypix_list {
  padding: 3rem 0;
}
.glypix_list .list_item {
  background: #fafafa;
}
.glypix_list .list_item .addr {
  font-size: 0.8rem;
}
.history_box {
  padding: 3rem 0;
}
.history_box img {
  max-width: 100%;
}
.webcam-cont {
  position: relative;
  width: 640px;
  height: 800px;
  max-width: 100%;
  overflow: hidden;
}
.webcam-cont .webcam,
.webcam-cont .video2,
.webcam-cont .canv,
.webcam-cont .canv2,
.webcam-cont .webcam-bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.webcam-cont .webcam {
  z-index: 19;
}
.webcam-cont .canv {
  z-index: 20;
}
.webcam-cont .webcam-bg {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10;
  /*background: url(webcam.jpeg) no-repeat center bottom;
  background-size: auto 100%;*/
}
.webcam-cont .webcam-cape,
.webcam-cont .webcam-cape .cbefore,
.webcam-cont .webcam-cape .cafter {
  background-size: 100% auto;
  position: absolute;
  text-align: center;
  color: #ccc;
  padding-top: 18%;
  font-style: italic;
  font-family: cursive;
  z-index: 200;
}
.webcam-cont .webcam-cape {
  background: url(cape.png) no-repeat center top;
  background-size: 100% auto;
}
.webcam-cont .webcam-cape .cbefore {
  content: "";
  background: url(left_cape.png) no-repeat center top;
  display: block;
  background-size: 100% auto;
  top: 0;
}
.webcam-cont .webcam-cape .cafter {
  content: "";
  background: url(right_cape.png) no-repeat center top;
  display: block;
  background-size: 100% auto;
  top: 0;
}
.webcam-cont .webcam-bg img {
  bottom: 0px;
  position: absolute;
}
.webcam-cont .loading {
  width: 100%;
  height: 100%;
  z-index: 100;
  left: 0;
  right: 0;
  position: absolute;
  background: url(spinner.gif) no-repeat center center;
}
.webcam-cont .mirror-cont {
  position: absolute;
  background: url(mirror.png) no-repeat center center;
  background-size: 100% auto;
  overflow: hidden;
  width: 570px;
  height: 788px;
  z-index: 30;
  display: none;
}
.webcam-cont .mirror-cont .mirror-in-cont {
  top: 126px;
  left: 95px;
  position: absolute;
  overflow: hidden;
  width: 375px;
  height: 518px;
  border-radius: 348px;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 100%;
  }
}

@media screen and (max-width: 720px) {
  .slider {
    padding: 1rem;
  }
  .glypix_list .list_item .desc {
    display: none;
  }
}
