html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ========= GLOBAL ========= */
body,
html {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  color: #434345;
  height: 100%;
  scroll-behavior: smooth;
}

html.menu-active {
  overflow: hidden;
}

/* ========= HEADINGS ========= */
p {
  line-height: 1.4;
  margin-bottom: 1.5em;
}

small {
  font-size: 14px;
}

em {
  font-style: italic;
}

/* ========= ELEMENTS ========= */

/* PAGE */
section {
  box-sizing: border-box;
}

/* LINKS */
a {
  color: inherit;
  transition: all 0.25s ease;
}

a:focus-visible {
  outline: none;
  box-shadow: 0 4px 0 #00aeef;
}

a:not(.gl-button, .gl-button-icon):hover {
  color: #00aeef;
}

p a {
  text-decoration: underline;
}

/* IMAGES */
img {
  max-width: 100%;
}

/* LISTS */
ul {
}

li {
  margin-bottom: 0.5em;
}

/* HORIZONTAL LINE */
hr {
  border: none;
  border-top: 1px solid #cccccc;
}

/* DETAILS SUMMARY */
details {
}

summary {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  list-style: none;
  margin-bottom: 12px;
}

/* FORMS */
input,
select,
textarea {
  border-radius: 18px;
  box-shadow: 0 0 0 1px #434345;
  width: 100%;
  max-width: 500px;
  border: none;
  outline: none;
  padding: 6px 12px;
  font-family: proxima-nova, sans-serif;
  font-size: inherit;
  transition: all 0.35s ease;
  appearance: none;
  background-color: white;
  box-sizing: border-box;
}

textarea {
  border-radius: 18px 18px 0 18px;
}

select {
  background: url(../images/icon-chevron-down.svg) white no-repeat right center /
    contain;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: 0 0 4px 1px #00aeef;
}

input[type="checkbox"],
input[type="radio"] {
  width: 1em;
  height: 1em;
  margin: 0 6px 0 0;
  padding: 0;
  border-radius: 3px;
}

input[type="radio"] {
  border-radius: 99px;
}

input[type="checkbox"]:checked {
  background: url(../images/icon-check.svg) no-repeat center center / contain;
}

input[type="radio"]:checked {
  background: #434345;
  box-shadow: 0 0 0 1px #434345, 0 0 0 4px white inset;
}

input[type="radio"]:checked:focus {
  box-shadow: 0 0 0 2px #00aeef, 0 0 0 4px white inset;
}

button {
  font-size: inherit;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
}

label {
  font-size: inherit;
  font-weight: 600;
  display: block;
  margin-bottom: 6px;
  white-space: normal;
}

input[type="checkbox"] ~ label,
input[type="radio"] ~ label {
  display: inline-block;
  vertical-align: top;
}

/* FORMS */
.gl-form {
}
.gl-form__item {
  margin-bottom: 1em;
  white-space: nowrap;
}

.errMsg {
  color: #ff0000;
  padding-bottom: 15px;
}
.successMsg {
  color: #7aad27;
  font-size: 1.4rem;
  font-weight: bold;
}

/* ========= LAYOUT ========= */
.gl-body {
  height: 100%;
}

.gl-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
}

.gl-wrapper--narrow {
  max-width: 1200px;
}

.gl-wrapper--thin {
  max-width: 600px;
}

.gl-layout {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -48px;
  font-size: 0;
}

.gl-layout--huge {
  margin-left: -96px;
}

.gl-layout--huge .gl-layout__item {
  padding-left: 96px;
}

.gl-layout--middle .gl-layout__item {
  vertical-align: middle;
}

.gl-layout .gl-layout__item {
  display: inline-block;
  font-size: 1rem;
}

.gl-layout__item {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-left: 48px;
}

/* ========= SPACING ========= */
.col-1\/1 {
  width: 100%;
}

.col-1\/2 {
  width: 50%;
}

.col-1\/3 {
  width: 33.33333%;
}

.col-2\/3 {
  width: 66.66666%;
}

.col-1\/4 {
  width: 25%;
}

.col-3\/4 {
  width: 75%;
}

.col-1\/5 {
  width: 20%;
}

.col-2\/5 {
  width: 40%;
}

.col-3\/5 {
  width: 60%;
}

.col-4\/5 {
  width: 80%;
}

.col-push-1\/1 {
  margin-left: 100%;
}

.col-push-1\/2 {
  margin-left: 50%;
}

.col-push-1\/3 {
  margin-left: 33.33333%;
}

.col-push-2\/3 {
  margin-left: 66.66666%;
}

.col-push-1\/4 {
  margin-left: 25%;
}

.col-push-3\/4 {
  margin-left: 75%;
}

.col-push-1\/5 {
  margin-left: 20%;
}

.col-push-2\/5 {
  margin-left: 40%;
}

.col-push-3\/5 {
  margin-left: 60%;
}

.col-push-4\/5 {
  margin-left: 80%;
}

/* ========= TYPOGRAPHY ========= */
.gl-heading-huge {
  font-size: 44px;
  font-weight: 300;
  margin-bottom: 0.5em;
}

.gl-heading-large {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 0.25em;
  display: flex;
  align-items: center;
}

.gl-heading-large i {
  margin-right: 12px;
}

.gl-heading-medium {
  font-size: 20px;
}

.gl-heading-medium.camera-wait {
  color: #ec008c;
}

.gl-heading-small {
  font-size: 16px;
  font-weight: 600;
}

/* ========= FORMS ========= */

/* ========= COMPONENTS ========= */

/* EMBELLISHMENTS */
.gl-embellishment {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: -1;
}

.gl-embellishment.one {
  left: 70%;
  position: absolute;
  top: 20%;
  width: 30%;
}

.gl-embellishment.two {
  max-width: 400px;
  margin: auto;
  margin-top: -48px;
  margin-bottom: 24px;
}

.gl-embellishment.three {
  position: absolute;
  bottom: 0;
  max-height: 300px;
}

.gl-embellishment:before {
  content: "";
  background: no-repeat center center / contain;
  width: 100%;
  padding-bottom: 100%;
  display: block;
}

.gl-embellishment.one:before {
  background-image: url(../images/graphic-embellishment-1.png);
  width: 100vw;
  padding-bottom: 100vw;
}

.gl-embellishment.two:before {
  background-image: url(../images/graphic-embellishment-2.png);
  animation: spin 60s linear forwards infinite;
}
em {
  font-style: italic;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.gl-star-heading {
  padding: 1.5px 0;
}
.gl-font-500 {
  font-weight: 500;
}
.gl-height-30 {
  height: 30px;
}
.details-icons {
  width: 20px;
  vertical-align: bottom;
  margin-right: 5px;
}
.gl-embellishment.three:before {
  background-image: url(../images/graphic-embellishment-3.png);
  background-position: bottom left;
}

/* ICONS */
.gl-icon {
  color: inherit;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  position: relative;
  vertical-align: text-bottom;
}

.gl-icon--round {
  border-radius: 99px;
  overflow: hidden;
}

.gl-icon--reverse {
  transition: all 0.25s ease;
  filter: brightness(0) invert(1);
}

.gl-icon:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: no-repeat center center / contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
}

.gl-icon--round:before {
  width: 65%;
  height: 65%;
}

.icon-accessible:before {
  background-image: url(../images/icon-accessible.svg);
}

.icon-bus:before {
  background-image: url(../images/icon-bus.svg);
}

.icon-calendar-range:before {
  background-image: url(../images/icon-calendar-range.svg);
}

.icon-check:before {
  background-image: url(../images/icon-check.svg);
}

.icon-chevron-down:before {
  background-image: url(../images/icon-chevron-down.svg);
}

.icon-clock:before {
  background-image: url(../images/icon-clock.svg);
}

.icon-closed-caption:before {
  background-image: url(../images/icon-closed-caption.svg);
}

.icon-code:before {
  background-image: url(../images/icon-code.svg);
}

.icon-g0000:before {
  background-image: url(../images/icon-g0000.svg);
}

.icon-g0000-light:before {
  background-image: url(../images/icon-g0000-light.svg);
}

.icon-g0001:before {
  background-image: url(../images/icon-g0001.svg);
}

.icon-g0001-light:before {
  background-image: url(../images/icon-g0001-light.svg);
}

.icon-glyp:before {
  background-image: url(../images/icon-glyp.svg);
}

.icon-glyp-ring:before {
  background-image: url(../images/icon-glyp-ring.svg);
}

.icon-headphones:before {
  background-image: url(../images/icon-headphones.svg);
}

.icon-mobile-device:before {
  background-image: url(../images/icon-mobile-device.svg);
}

.icon-mobile-device-rear-cam:before {
  background-image: url(../images/icon-mobile-device-rear-cam.svg);
}

.icon-on-site:before {
  background-image: url(../images/icon-on-site.svg);
}

.icon-parking:before {
  background-image: url(../images/icon-parking.svg);
}

.icon-scan:before {
  background-image: url(../images/icon-scan.svg);
}

.icon-scan-code:before {
  background-image: url(../images/icon-scan-code.svg);
}

.icon-touch-app:before {
  background-image: url(../images/icon-touch-app.svg);
}

.icon-traffic:before {
  background-image: url(../images/icon-traffic.svg);
}

.icon-umbrella:before {
  background-image: url(../images/icon-umbrella.svg);
}

.icon-weather:before {
  background-image: url(../images/icon-weather.svg);
}

/* BUTTONS */
.gl-button {
  padding: 12px 32px;
  border-radius: 99px;
  margin: 0 1em 1.5em 0;
  transition-property: all, box-shadow;
  transition-duration: 0.2s, 0.4s;
  transition-delay: 0s, 0.1s;
  transition-timing-function: ease;
  display: inline-block;
  text-align: center;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

/* BUTTONS / INLINE */
.gl-button--inline {
  width: auto;
  text-decoration: none;
}

.gl-button--inline:last-child {
  width: auto;
  margin-right: 0;
}

/* BUTTONS / PRIMARY */
.gl-button--primary,
.gl-button--primary-not-hover {
  background: #434345;
  color: white;
  text-decoration: none;
  font-weight: 500;
  position: relative;
}

.gl-button--primary span {
  /* Use span tag on label*/
}

.gl-button--primary span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 99px;
}

.gl-button--primary:before,
.gl-button--primary:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  background: #ffd600;
  border-radius: 99px;
  filter: blur(6px);
  transition: all 0.35s ease;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: -1;
}

.gl-button--primary:after {
  width: 30%;
  background: #92c83e;
}

.gl-button--primary:hover,
.gl-button--primary:focus-visible {
  background: white;
  color: #434345;
  box-shadow: -6px -2px 8px #ec008c, 3px 4px 6px #00aeef;
}

.gl-button--primary:hover:before,
.gl-button--primary:focus-visible:before {
  top: 20%;
  left: 96%;
  opacity: 1;
}

.gl-button--primary:hover:after,
.gl-button--primary:focus-visible:after {
  left: 15%;
  top: 82%;
  opacity: 1;
}

.gl-button--primary:hover .gl-icon--reverse,
.gl-button--primary:focus-visible .gl-icon--reverse {
  filter: brightness(1) invert(0);
}

/* BUTTONS / REVERSE */
.gl-button--reverse {
  color: #434345;
  background: white;
}

/* BUTTONS / ROW */
.gl-button--row {
  margin-right: 0;
}

/* BUTTONS / NO MARGIN */
.gl-button--no-margin {
  margin: 0;
}

/* BUTTONS / LARGE */
.gl-button--large {
  padding: 18px 24px;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  width: 100%;
  margin-bottom: 1em;
}

.gl-button--primary.gl-button--large:hover {
  box-shadow: -6px -4px 12px #ec008c, 3px 4px 9px #00aeef;
}

.gl-button--primary.gl-button--large:hover:before {
  top: 24%;
  left: 93%;
}

.gl-button--primary.gl-button--large:hover:after {
  top: 75%;
  left: 13%;
}

.gl-button--disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
}

/* BUTTONS / ICON */
.gl-button-icon {
  color: inherit;
  transition: all 0.2s ease;
}

.gl-button-icon:hover {
  text-shadow: 0 0 1px #434345, 0 0 1px #434345, 0 0 1px #434345,
    0 0 1px #434345, 0 0 1px #434345, 0 0 1px #434345;
  color: white;
}

/* SECTIONS */
.gl-section {
  position: relative;
  padding: 48px 0;
}

.gl-section--small {
  padding: 24px 0;
}

.gl-section#contact {
  padding-bottom: 120px;
}

/* SECTIONS / SPLASH */
.gl-splash {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.gl-splash__logo {
  text-align: right;
  margin-bottom: 48px;
}

.gl-splash__scroll {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 1em;
  left: 0;
}

.gl-splash__scroll a {
  outline: none;
  text-decoration: none;
}

.gl-splash__scroll i {
  margin-top: 3px;
}

/* PAGE */
.gl-page {
  overflow: hidden;
  overflow-y: auto;
  flex: 1;
  height: 100%;
  scroll-behavior: smooth;
}

/* PAGE / PROJECT INFORMATION */
.gl-page-thumbnail {
  margin-bottom: 24px;
}

.gl-page-credits {
}

.gl-page-location {
}

.gl-page-details {
}

.gl-page-story {
}

.gl-page-showcase {
}

/* PAGE / G0000 */
.gl-page__g0000 {
  background: url(../images/g0000-background.jpg) no-repeat center center /
    cover;
}

/* PAGE / G0001 */
.gl-page__g0001 {
  background: url(../images/graphic-g0001-background.jpg) repeat center center /
    128px auto;
}

.gl-page__g0001-backdrop {
  background: url(../images/image-g0001-backdrop.png) fixed no-repeat center
    bottom / auto 115%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* ROW */
.gl-row {
  margin-bottom: 24px;
}

.gl-row--large {
  margin-bottom: 48px;
}

.gl-row--small {
  margin-bottom: 12px;
}

.gl-row--tiny {
  margin-bottom: 6px;
}

/* INLINE FORM */

.gl-inline-form {
  display: flex;
  width: 100%;
  max-width: 500px;
  position: relative;
}

.gl-inline-form--centered {
  margin: auto;
}

.gl-inline-form .gl-form__item {
  flex: 1;
  margin: 0;
}

.gl-inline-form .gl-form__item input {
  box-sizing: border-box;
  height: 100%;
  font-size: 20px;
  border-radius: 999px;
  padding: 9px calc(30% + 24px) 9px 24px;
}

.gl-inline-form button {
  height: 100%;
  border-radius: 0 99px 99px 0;
  margin: 0;
  padding: 9px 24px;
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
}

.gl-inline-form__submit-label {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

/* HEADER */
.gl-header {
  padding: 24px 0;
}

.gl-header__logo {
  max-width: 260px;
}

.gl-header__logo a {
  display: inline-block;
}

.gl-header__nav {
  text-align: right;
  font-size: 32px;
}

.gl-header__nav .hide,
.menu-active .gl-header__nav .show {
  display: none;
}

.menu-active .gl-header__nav .hide {
  display: inline-block;
}

.gl-header__nav a {
}

/* HEADER MENU */
.gl-header-menu {
  width: 100%;
  line-height: 1.4;
  opacity: 0;
  pointer-events: none;
  transition: all 0.35s ease;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  padding: 24px;
  box-sizing: border-box;
  z-index: 999;
}

.menu-active .gl-header-menu {
  display: block;
  pointer-events: all;
  transform: translateX(0);
  opacity: 1;
}

.gl-header-menu a {
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}

.gl-header-menu a:hover {
  color: inherit;
  box-shadow: 0 2px 0 #cccccc;
}

.gl-header-menu__toggle {
  font-size: 16px;
}

/* FOOTER */
.gl-footer {
  width: 100%;
  background: white;
  padding: 0 0 12px;
}

.gl-footer__content {
  border-top: 1px solid #cccccc;
  padding-top: 12px;
}

.gl-footer__copy {
  font-size: 14px;
}

/* FOOTER MENU */
.gl-footer-menu {
  margin-bottom: 8px;
}

.gl-footer-menu li {
  font-size: 0;
}

.gl-footer-menu li:before {
  content: "/";
  display: inline-block;
  color: #434345;
  font-size: 14px;
  margin-right: 6px;
  margin-left: 3px;
}

.gl-footer-menu li:first-child:before {
  content: none;
}

.gl-footer-menu a {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}

.gl-footer-menu a:hover {
  color: inherit;
  box-shadow: 0 1px 0 #cccccc;
}

/* SOCIAL MENU */
.gl-social-menu {
  margin-bottom: 24px;
}

.gl-social-menu li {
  margin-right: 12px;
}

.gl-social-menu a {
  font-size: 24px;
}

.gl-social-menu__heading {
  margin-bottom: 12px;
}

/* CARDS */
.gl-card {
  position: relative;
  width: 80vw;
  height: 100%;
  border: 1px solid #cccccc;
}

.gl-card:before {
  width: 100%;
  padding-bottom: 56.25%;
  display: block;
  vertical-align: bottom;
}

.gl-card__content {
  width: 100%;
  box-sizing: border-box;
}

.gl-card__thumbnail {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
}

.gl-card__thumbnail a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gl-card__thumbnail a:not(:hover):focus-visible {
  box-shadow: 0 0 0 4px #00aeef inset;
}

.gl-card__thumbnail img {
  position: relative;
  z-index: -1;
  width: 100%;
  min-height: 100%;
}

.gl-card__thumbnail:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: white;
  background: linear-gradient(40deg, white 20%, transparent 28%);
  pointer-events: none;
}

.gl-card--alt .gl-card__thumbnail:before {
  content: none;
}

.gl-card--video .gl-card__thumbnail:after {
  content: "\f04b";
  font-family: "Font Awesome 6 Free";
  font-weight: bold;
  font-size: 2em;
  color: white;
  background: rgba(0, 0, 0, 0.65);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.35);
  text-indent: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 99px;
  overflow: hidden;
  pointer-events: none;
  border: 3px solid white;
}

.gl-card__icon {
  font-size: 2em;
  margin-top: -2em;
  margin-bottom: 0;
  position: relative;
  z-index: 9;
  pointer-events: none;
}

.gl-card__details {
  padding: 12px 12px 24px;
}

.gl-card--alt .gl-card__details {
  padding: 0 12px;
}

.gl-card__title {
  font-weight: 500;
  font-size: 18px;
}

.gl-card__title a {
  text-decoration: none;
}

.gl-card__title a:focus {
  color: #00aeef;
  box-shadow: none;
}

.gl-card--alt .gl-card__title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gl-card--alt .gl-card__title a {
  display: block;
  position: relative;
  padding-left: calc(2em + 12px);
}

.gl-card--alt .gl-card__title a:before {
  content: "";
  display: block;
  width: 2.2em;
  height: 2.2em;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/icon-glyp-ring.svg) no-repeat center center /
    contain;
}

.gl-card--alt .gl-card__title .gl-icon {
  width: 0.9em;
  height: 1.3em;
  position: absolute;
  top: 1.1em;
  left: 1.15em;
  transform: translate(-50%, -50%);
  margin-right: -2px;
}

.gl-card__location {
  margin-bottom: 12px;
  font-weight: 300;
}

/* CAROUSEL */
.gl-carousel {
  margin: 24px -24px 0;
  padding: 24px;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.gl-carousel::-webkit-scrollbar {
  display: none;
}

.gl-carousel__list {
  display: flex;
}

.gl-carousel__item {
  vertical-align: top;
  display: inline-block;
  padding-right: 24px;
  white-space: normal;
  font-size: 1rem;
}

/* MODAL */
.gl-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 24px;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
}

.gl-modal--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.gl-modal__content {
  background: white;
  border-radius: 18px;
  padding: 24px;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.35);
  width: 100%;
  max-width: 600px;
  min-height: 200px;
  position: relative;
  z-index: 1;
  max-height: calc(100% - 48px);
  overflow-y: auto;
}

/* CREDIT TABLE */
.gl-credit-table {
  width: 100%;
}

.gl-credit-table th {
  min-width: 200px;
  text-align: left;
}

.gl-credit-table td {
  margin-bottom: 24px;
}

/* EPIC LOGO */
.gl-epic-logo {
  pointer-events: none;
  display: flex;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 20px 10px white;
}

.gl-epic-logo__icon {
  flex: 25%;
  margin-right: 12px;
}

.gl-epic-logo__wordmark {
  flex: 100%;
}

/* TODO - change to gl-epic-logo vertical */
.gl-epic-logo-vertical {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.gl-epic-logo-vertical__icon {
  width: 100%;
  max-width: 125px;
}

.gl-epic-logo-vertical__wordmark {
  width: 100%;
  max-width: 400px;
}

/* TAG */
.gl-tag {
  background: white;
  text-align: center;
  padding-bottom: 18px;
}

.gl-tag__copy {
  font-size: 14px;
  margin-bottom: 6px;
}

.gl-tag__logo img {
  margin-left: -1rem;
  max-width: 150px;
}

.gl-tag__logo a {
  pointer-events: all;
}

/* PREVIEW VIDEO */
.gl-preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  /*padding: 24px;*/
  background: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gl-g0001-camera-zoom video.gl-g0001-camera {
  transition: transform 0.25s ease;
  transform: scale(1.5);
}

.gl-g0001-camera-zoom.zoom-1 video.gl-g0001-camera {
  transform: scale(2);
}

.gl-g0001-camera-zoom.zoom-2 video.gl-g0001-camera {
  transform: scale(2.5);
}

.gl-preview-video .close-cont {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #fff;
  padding: 7px 10px;
}

.gl-preview-video .close-cont a {
  text-decoration: none;
}

.gl-preview-video__ar {
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.gl-preview-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* GUIDE STEP */
.gl-guide-step {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.gl-guide-step__header {
  padding-top: 24px;
  margin-bottom: 24px;
  background: white;
}

.gl-guide-step__content {
  flex: 1;
  /*overflow: auto;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

/* ASPECT RATIO */
.gl-aspect-ratio {
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  overflow: hidden;
  position: relative;
}

.gl-aspect-ratio--1\:1 {
  padding-bottom: 100%;
}

.gl-aspect-ratio--4\:3 {
  padding-bottom: 75%;
}

.gl-aspect-ratio--16\:9 {
  padding-bottom: 56.25%;
}

.gl-aspect-ratio__content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.gl-aspect-ratio--contain img {
  width: auto;
  height: 100%;
}

/* STEP NAV */
.gl-step-nav {
  font-size: 0;
}

.gl-step-nav__item {
  display: inline-block;
  font-size: 1rem;
  width: 0.75em;
  height: 0.75em;
  border-radius: 999px;
  background: #434345;
  opacity: 0.2;
  margin: 0 6px;
}

.gl-step-nav__item.current {
  opacity: 1;
}

.gl-step-nav__item span {
  /* .screen-reader-text */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.gl-step-nav__item a:focus {
  position: static;
  width: auto;
  height: auto;
}

/* MAP */
.gl-map {
  width: 100%;
  position: relative;
}

.gl-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* LANDSCAPE SCRIM */
.c-landscape-scrim {
  display: none;
  position: fixed;
  z-index: 9999999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: white;
}

.c-landscape-scrim__content {
  font-weight: 500;
  padding: 24px;
}

.c-landscape-scrim__graphic {
  position: relative;
  margin: 24px auto 0;
  width: 100px;
  height: 100px;
}

.c-landscape-scrim__graphic:before,
.c-landscape-scrim__graphic:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center center / 100px 100px;
  animation: rotate-90 2.25s ease-in-out infinite;
}

.c-landscape-scrim__graphic:before {
  background-image: url(../images/icon-phone.svg);
}

.c-landscape-scrim__graphic:after {
  animation: pulse-rotate 2.25s ease-in-out infinite;
  background-image: url(../images/icon-rotate.svg);
}

/* Detect the screen rotation */
@media (max-height: 500px) and (orientation: landscape) {
  .c-landscape-scrim {
    display: flex;
  }
}

/* KEYFRAME ANIMATIONS */
@keyframes pulse-rotate {
  from,
  10% {
    opacity: 0;
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(90deg);
  }
  30% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 1;
    transform: rotate(0);
  }
  to,
  90% {
    opacity: 0;
    transform: rotate(0);
  }
}

@keyframes rotate-90 {
  from,
  10% {
    opacity: 0;
    transform: rotate(90deg);
  }
  25% {
    opacity: 1;
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(0);
    opacity: 1;
  }
  to,
  90% {
    opacity: 0;
    transform: rotate(0);
  }
}

/* G0001 CAMERA */
.gl-g0001-camera {
  position: fixed;
  z-index: -2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: lightgrey;
}

.gl-g0001-camera video {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.gl-g0001-epic {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: calc(100% - 85px);
}

.gl-g0001-epic-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gl-g0001-epic-controls__header {
  margin-top: 24px;
}

.gl-g0001-epic-controls__footer {
}

.gl-g0001-epic__video {
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.gl-g0001-epic__video video {
  object-fit: contain;
  object-position: center top;
  height: 100%;
}

.gl-0001-epic__video-placement-guide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.gl-0001-epic__video-placement-guide img {
  object-fit: contain;
  object-position: center top;
  height: 100%;
}

/* G0001 CONTENT */
.gl-g0001-content {
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

.gl-guide-step__footer {
  background: white;
  padding-bottom: 24px;
}

/* ========= UTILITIES ========= */

/* ALIGNMENT */
.gl-align-center {
  text-align: center !important;
}

.gl-align-left {
  text-align: left !important;
}

.gl-align-right {
  text-align: right !important;
}

/* VISIBILITY */
.gl-hide {
  display: none !important;
}

/* LIST INLINE */
.gl-list-inline {
  margin: 0;
  padding: 0;
}

.gl-list-inline--centered li {
  margin: 0 6px;
}

.gl-list-inline li {
  display: inline-block;
}

/* SCREEN READER TEXT */
.screen-reader-text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.screen-reader-text a:focus,
.screen-reader-text input:focus,
.screen-reader-text button:focus {
  position: static;
  width: auto;
  height: auto;
}

/* ========= RESPONSIVE ========= */

/* TABLET AND ABOVE */
@media (min-width: 768px) {
  /* ========= ELEMENTS ========= */
  html.menu-active {
    overflow: auto;
  }

  /* ========= LAYOUT ========= */
  .gl-layout--reverse {
    direction: rtl;
  }

  .gl-layout--reverse .gl-layout__item {
    direction: ltr;
  }

  /* ========= SPACING ========= */
  .col-1\/1\@\tablet {
    width: 100%;
  }

  .col-1\/2\@\tablet {
    width: 50%;
  }

  .col-1\/3\@\tablet {
    width: 33.33333%;
  }

  .col-2\/3\@\tablet {
    width: 66.66666%;
  }

  .col-1\/4\@\tablet {
    width: 25%;
  }

  .col-3\/4\@\tablet {
    width: 75%;
  }

  .col-1\/5\@\tablet {
    width: 20%;
  }

  .col-2\/5\@\tablet {
    width: 40%;
  }

  .col-3\/5\@\tablet {
    width: 60%;
  }

  .col-4\/5\@\tablet {
    width: 80%;
  }

  .col-push-1\/1\@\tablet {
    margin-left: 100%;
  }

  .col-push-1\/2\@\tablet {
    margin-left: 50%;
  }

  .col-push-1\/3\@\tablet {
    margin-left: 33.33333%;
  }

  .col-push-2\/3\@\tablet {
    margin-left: 66.66666%;
  }

  .col-push-1\/4\@\tablet {
    margin-left: 25%;
  }

  .col-push-3\/4\@\tablet {
    margin-left: 75%;
  }

  .col-push-1\/5\@\tablet {
    margin-left: 20%;
  }

  .col-push-2\/5\@\tablet {
    margin-left: 40%;
  }

  .col-push-3\/5\@\tablet {
    margin-left: 60%;
  }

  .col-push-4\/5\@\tablet {
    margin-left: 80%;
  }

  /* ========= HEADINGS ========= */
  .gl-heading-large {
    font-size: 38px;
  }

  /* ========= COMPONENTS ========= */

  /* EMBELLISHMENTS */
  .gl-embellishment.one {
    top: -20%;
    height: auto;
  }

  .gl-embellishment.two {
    margin: 0 auto 24px;
  }

  .gl-embellishment.three {
    left: 50%;
    width: 50%;
    max-width: 800px;
    max-height: 300px;
  }

  /* BUTTONS */
  .gl-button {
    width: auto;
  }

  /* BUTTONS / LARGE */
  .gl-button--large {
    padding: 12px 36px;
    font-size: 24px;
    width: auto;
  }

  .gl-button--medium {
    padding: 9px 32px;
    font-size: 18px;
    font-weight: 400;
  }

  /* SECTIONS */
  .gl-section#contact {
    padding-bottom: 48px;
  }

  /* SECTIONS / SPLASH */
  .gl-splash {
    min-height: 100%;
  }

  .gl-splash__logo {
    margin-bottom: 24px;
  }

  .gl-splash__logo img {
    max-width: 88%;
  }

  /* BODY */
  .gl-body {
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* PAGE */
  .gl-page {
    min-height: 0;
  }

  /* HEADER */
  .gl-header {
    width: 100%;
    background: white;
  }

  .gl-header__nav {
    position: relative;
  }

  /* HEADER MENU */
  .gl-header-menu {
    position: static;
    line-height: 0.8;
    background: none;
    padding: 0;
    height: auto;
    transform: translateX(48px);
  }

  .gl-header-menu li {
    display: inline-block;
    margin-left: 36px;
  }

  .gl-header-menu__toggle {
    display: none !important;
  }

  /* FOOTER */
  .gl-footer {
  }

  /* SOCIAL MENU */
  .gl-social-menu {
    text-align: right;
  }

  .gl-social-menu ul:not(.gl-list-inline--centered) li {
    margin-right: 0;
    margin-left: 12px;
  }

  /* CARDS */
  .gl-card {
    border: none;
  }

  .gl-card:before {
    content: "";
    width: 100%;
    padding-bottom: 56.25%;
    display: block;
  }

  .gl-card__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .gl-card__thumbnail {
    height: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
    position: absolute;
  }

  .gl-card__thumbnail img {
    position: static;
  }

  .gl-card__thumbnail:before {
    background: linear-gradient(24deg, white 35%, transparent 85%);
  }

  .gl-card--video .gl-card__thumbnail:after {
    font-size: 2.75em;
  }

  .gl-card__icon {
    margin-top: 0;
    margin-bottom: 0.25em;
    font-size: 3em;
  }

  .gl-card__details {
    padding: 0 16px 24px;
    position: relative;
    z-index: 9;
    text-shadow: 0 0 10px white, 0 0 10px white, 0 0 20px white, 0 0 30px white,
      0 0 40px white;
  }

  .gl-card--alt .gl-card__details {
    position: relative;
    padding: 12px 120px 24px 12px;
    pointer-events: none;
  }

  .gl-card--alt .gl-card__details:before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 24px);
    background: linear-gradient(
      70deg,
      rgba(255, 255, 255, 0.93) 75%,
      transparent 95%
    );
    position: absolute;
    top: 0;
    left: 0;
  }

  .gl-card__description {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .gl-card__title {
    font-weight: 400;
    font-size: 24px;
  }
  .gl-card--alt .gl-card__title {
    pointer-events: all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 2.25em;
  }

  .gl-card--alt .gl-card__title a {
    display: block;
    position: relative;
    padding-left: calc(2em + 12px);
  }

  .gl-card__hover {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    z-index: -1;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: all 0.5s ease;
  }

  .gl-card__hover:before,
  .gl-card__hover:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 2em;
    height: 2em;
    background: #ffd600;
    border-radius: 0;
    filter: blur(8px);
    transition: all 0.35s ease;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .gl-card__hover:after {
    background: #92c83e;
  }

  .gl-card:hover .gl-card__hover {
    box-shadow: -6px -8px 14px #ec008c, 2px 2px 14px #00aeef;
  }

  .gl-card:hover .gl-card__hover:before {
    height: 30%;
    width: 10%;
    top: 11%;
    left: 97%;
    opacity: 0.6;
    z-index: 99;
  }

  .gl-card:hover .gl-card__hover:after {
    width: 40%;
    height: 20%;
    top: 93%;
    left: 17%;
    opacity: 0.6;
  }

  /* EPIC INFORMATION PAGE */
  .gl-epic-logo__icon {
    margin-right: 24px;
  }

  /* INLINE FORM */
  .gl-inline-form__submit-label {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: initial;
  }

  /* ========= UTILITIES ========= */
  /* VISIBILITY */
  .gl-hide\@tablet {
    display: none !important;
  }

  .gl-wrapper .gl-header__nav .gl-header-menu {
    display: block;
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
  }
  .gl-header__nav > a {
    display: none;
  }
}

/* DESKTOP AND ABOVE */
@media (min-width: 980px) {
  /* ========= LAYOUT ========= */
  .gl-wrapper {
    padding: 0 48px;
  }

  /* ========= SPACING ========= */
  .col-1\/1\@desktop {
    width: 100%;
  }

  .col-1\/2\@desktop {
    width: 50%;
  }

  .col-1\/3\@desktop {
    width: 33.33333%;
  }

  .col-2\/3\@desktop {
    width: 66.66666%;
  }

  .col-1\/4\@desktop {
    width: 25%;
  }

  .col-3\/4\@desktop {
    width: 75%;
  }

  .col-1\/5\@desktop {
    width: 20%;
  }

  .col-2\/5\@desktop {
    width: 40%;
  }

  .col-3\/5\@desktop {
    width: 60%;
  }

  .col-4\/5\@desktop {
    width: 80%;
  }

  .col-push-1\/1\@desktop {
    margin-left: 100%;
  }

  .col-push-1\/2\@desktop {
    margin-left: 50%;
  }

  .col-push-1\/3\@desktop {
    margin-left: 33.33333%;
  }

  .col-push-2\/3\@desktop {
    margin-left: 66.66666%;
  }

  .col-push-1\/4\@desktop {
    margin-left: 25%;
  }

  .col-push-3\/4\@desktop {
    margin-left: 75%;
  }

  .col-push-1\/5\@desktop {
    margin-left: 20%;
  }

  .col-push-2\/5\@desktop {
    margin-left: 40%;
  }

  .col-push-3\/5\@desktop {
    margin-left: 60%;
  }

  .col-push-4\/5\@desktop {
    margin-left: 80%;
  }

  /* ========= COMPONENTS ========= */

  /* EMBELLISHMENTS */
  .gl-embellishment.one {
    top: 20%;
    left: 70%;
    height: auto;
  }

  .gl-embellishment.one:before {
    width: 60vw;
    padding-bottom: 60vw;
  }

  .gl-embellishment.three {
    max-height: 400px;
  }

  /* SECTIONS */
  .gl-section {
    padding: 96px 0;
  }

  .gl-section--small {
    padding: 48px 0;
  }

  /* SECTIONS / SPLASH */
  .gl-splash__logo img {
    max-width: 66%;
  }

  /* CARDS */
  .gl-card {
    width: 600px;
  }

  /* CAROUSEL */
  .gl-carousel {
    margin: 0 -48px;
    padding: 24px 48px;
  }

  .gl-carousel__item:last-child {
    padding-right: 48px;
  }
}

/* WIDESCREEN AND ABOVE */
@media (min-width: 1300px) {
  /* ========= HEADINGS ========= */
  .gl-heading-huge {
    font-size: 70px;
  }

  /* ========= COMPONENTS ========= */

  /* EMBELLISHMENTS */
  .gl-embellishment.one {
    top: 10%;
    left: auto;
    right: -5%;
    width: 40%;
    max-width: 750px;
  }

  .gl-embellishment.one:before {
    width: 100%;
    padding-bottom: 100%;
  }

  .gl-embellishment.three {
    max-height: 650px;
  }

  /* CAROUSEL */
  .gl-carousel {
    padding: 24px;
    margin: 0 -24px;
  }

  .gl-carousel__item:last-child {
    padding-right: 24px;
  }
}

@media (max-width: 720px) {
  .gl-guide-step__content.mfix {
    padding-top: 70%;
  }
  .gl-page__g0000 {
    background-size: auto 100%;
  }
}
